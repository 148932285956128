import { Link } from "react-router-dom";
import data from "../../config/data.json";

const Footer = () => {
  return (
    <footer className="footer_section">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-3 col-sm-6">
            <div className="single_feature_footer">
              <Link to="/courses">
                <img
                  src={require("../../assets/imgs/black-logo.png")}
                  alt="/courses"
                />
              </Link>
              <p>
                An online IT training provider and career advisor, helping
                ambitious individuals uplift their skills to succeed in an
                ever-changing world
              </p>
              <div className="footer_contact_info">
                <Link to="/contact-us">
                  <i className="icon_mail_alt"></i>
                  opsmanagers@verticalcareers.org
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="single_feature_footer footer_nav">
              <h4>Popular Courses</h4>
              <ul>
                {data.POPULAR_COURSES.map((course) => {
                  return (
                    <li key={course.id}>
                      <Link to={`/courses/${course.id}`}>{course.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="single_feature_footer footer_nav">
              <h4>Programs</h4>
              <ul>
                <li>
                  <Link to="/programs">ISO Program</Link>
                </li>
                <li>
                  <Link to="/programs">Cyber Security Program</Link>
                </li>
                <li>
                  <Link to="/programs">Network Program</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="single_feature_footer footer_nav">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="copyright_part">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-8">
                  <p>
                    © Copyright Vertical Careers 2022 Design by{" "}
                    <Link
                      to="http://www.easematter.com"
                      className="text_underline"
                    >
                      EaseMatter.
                    </Link>{" "}
                    All rights reserved.
                  </p>
                </div>
                <div className="col-lg-6 col-md-4">
                  <div className="social_icon">
                    <Link to="https://www.facebook.com/Vertical.Career.Creators">
                      <i className="social_facebook"></i>
                    </Link>
                    {/* <Link to="/courses">
                      <i className="social_twitter"></i>
                    </Link>
                    <Link to="/courses">
                      <i className="social_youtube"></i>
                    </Link> */}
                    <Link to="https://www.linkedin.com/company/vertical-career-creators/">
                      <i className="social_linkedin"></i>
                    </Link>
                    {/* <Link to="/courses">
                      <i className="social_skype"></i>
                    </Link>
                    <Link to="/courses">
                      <i className="social_instagram"></i>
                    </Link>
                    <Link to="/courses">
                      <i className="social_pinterest"></i>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={require("../../assets/imgs/icon/shape_01.png")}
        alt="#"
        className="footer_sharp_1"
      />
      <img
        src={require("../../assets/imgs/icon/about_shape_02.png")}
        alt="#"
        className="footer_sharp_2 custom-animation2"
      />
      <img
        src={require("../../assets/imgs/icon/about_shape_03.png")}
        alt="#"
        className="footer_sharp_3 custom-animation3"
      />
    </footer>
  );
};

export default Footer;

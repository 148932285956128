import "./assets/styles/style.css";
// import "./assets/styles/flaticon.css";
// import "./assets/styles/meeting.css";
// import "./assets/vendors/owl_carousel/css/owl.carousel.css";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AllCourses from "./pages/AllCourses";
import CourseDetails from "./pages/CourseDetails";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/programs",
        element: <AllCourses />,
      },
      {
        path: "/courses/:id",
        element: <CourseDetails />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: function () {
        var maxWidth = 768;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  return <RouterProvider router={router} />;
}

export default App;

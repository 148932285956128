import { Link } from "react-router-dom";

const NAV_LINKS = [
  {
    title: "Home",
    hasChildren: false,
    page: "/",
  },
  {
    title: "Programs",
    hasChildren: false,
    page: "/programs",
  },
  {
    title: "Contact us",
    hasChildren: false,
    page: "/contact-us",
  },
];

const Nav = (props) => {
  return (
    <nav className="navbar_bar">
      <ul>
        {NAV_LINKS.map((link, idx) => {
          if (link.hasChildren) {
            return (
              <li key={idx} className="mega_menu_dropdown">
                <a
                  className={`mega_menu_dropdown-toggle nav-link ${
                    idx === 0 ? "active" : ""
                  }`}
                  href={link.page}
                >
                  {link.title}
                </a>
                <ul>
                  {link.children.map((subLink, jdx) => {
                    return (
                      <li key={jdx}>
                        <a href={subLink.page}>{subLink.title}</a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          } else {
            return (
              <li key={idx}>
                <Link to={link.page}>{link.title}</Link>
              </li>
            );
          }
        })}
      </ul>
    </nav>
  );
};

export default Nav;

// import { useScript } from "../../hooks/useScript";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/styles/style.css";

const Banner = () => {
  // const aosStatus = useScript("./vendors/aos/aos.js", "aos");
  // const customStatus = useScript("./js/custom.js", "custom");

  const banner = (
    <OwlCarousel
      className="banner_part"
      items="1"
      loop
      nav="true"
      navText={["<i class='arrow_left'></i>", "<i class='arrow_right'></i>"]}
      dots="false"
      autoplay="true"
      autoplayHoverPause="true"
      smartSpeed="500"
      animateOut="slideOutLeft"
      animateIn="slideInRight"
      responsive={{
        0: {
          nav: false,
        },
        768: {
          nav: true,
        },
      }}
    >
      <div className="single_banner_part bg_3">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8">
              <div className="banner_iner">
                <h5>Welcome To Vertical</h5>
                <h2>Where Knowledge Empowers You</h2>

                <Link to="/programs" className="btn_2">
                  Our Prorgams
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="single_banner_part bg_2">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8">
              <div className="banner_iner">
                <h5>Vertical provides</h5>
                <h3 className="bannercolor">
                  An Elite group of instructors with hands-on experience in
                  reputable global and regional organizations
                </h3>
                <Link to="/programs" className="btn_2">
                  Our Prorgams
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="single_banner_part bg_1">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8">
              <div className="banner_iner">
                <h5>in Our Organization</h5>
                <h3 className="bannercolor">
                  We are committed to provide our partners with High Quality,
                  Flexible, and Customized Solutions.
                </h3>
                <Link to="/programs" className="btn_2">
                  Our Prorgams
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OwlCarousel>
  );

  return (
    <HelmetProvider>
      {banner}
      <Helmet>
        {/* <script type="text/javascript" src="./vendors/aos/aos.js" async /> */}
        <script type="text/javascript" src="./js/custom.js" async />
      </Helmet>
      ;
    </HelmetProvider>
  );
};

export default Banner;

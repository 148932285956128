import emailjs from "@emailjs/browser";
import data from "../config/data.json";
import { useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const Contact = () => {
  const form = useRef();
  const [type, setType] = useState("");
  const [time, setTime] = useState("");
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [fromName, setFromName] = useState("");
  const [fromNameError, setFromNameError] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [subject, setSubject] = useState("");
  const [organizationText, setOrganizationText] = useState("");
  const [currentProfessionText, setCurrentProfessionText] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isDate, setIsDate] = useState(false);

  const setTypeHandler = (e) => {
    setType(e.target.value);
  };

  const setTimeHandler = (e) => {
    setTime(e.target.value);
  };

  const setCountryHandler = (e) => {
    setCountry(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    let errorForm = false;

    if (fromName === null || fromName === "") {
      setFromNameError(true);
      errorForm = true;
    }

    if (userEmail === null || userEmail === "") {
      setUserEmailError(true);
      errorForm = true;
    }

    if (phone === null || phone === "") {
      setPhoneError(true);
      errorForm = true;
    }

    if (country === null || country === "") {
      setCountryError(true);
      errorForm = true;
    }

    if (errorForm) return;

    setFromNameError(false);
    setUserEmailError(false);
    setPhoneError(false);
    setCountryError(false);

    emailjs
      .sendForm(
        "service_vnpi86k",
        "template_befeu5h",
        form.current,
        "0pwgEjVvK_j2JOqai"
      )
      .then(
        (result) => {
          toast.success("Email was sent successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });

          setFromName("");
          setUserEmail("");
          setPhone("");
          setSubject("");
          setOrganizationText("");
          setCurrentProfessionText("");
          setDateOfBirth("");
          setNumber("");
          setMessage("");
          setTime("");
          setType("");
          setCountry("");
        },
        (error) => {
          toast.error("A problem happened while sending your email", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        }
      );
  };

  return (
    <>
      <ToastContainer />
      <section className="breadcrumb_part blog_grid_bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 ">
              <div className="breadcrumb_iner">
                <h2>Contact Form</h2>
                <div className="breadcrumb_iner_link">
                  <a href="index.html">Home</a>
                  <i className="arrow_carrot-right"></i>
                  <span>Contact</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="review_form contact_form section_padding">
        <div className="container">
          <h3 data-aos="fade-up" data-aos-duration="1200">
            Feel Free to Contact with Us
          </h3>
          <form
            ref={form}
            data-aos="fade-up"
            data-aos-duration="1600"
            onSubmit={sendEmail}
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="form_single_item">
                  <input
                    type="text"
                    name="from_name"
                    placeholder="Name*"
                    value={fromName}
                    onChange={(e) => setFromName(e.target.value)}
                  />
                  {fromNameError && (
                    <span className="error-message">Name is required</span>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form_single_item">
                  <input
                    type="email"
                    name="user_email"
                    placeholder="Email*"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                  {userEmailError && (
                    <span className="error-message">Email is required</span>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form_single_item">
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Phone*"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {phoneError && (
                    <span className="error-message">
                      Phone number is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form_single_item">
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form_single_item">
                  <input
                    type="text"
                    name="organization_text"
                    placeholder="Organization"
                    value={organizationText}
                    onChange={(e) => setOrganizationText(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form_single_item">
                  <input
                    type="text"
                    name="current_profession_text"
                    placeholder="Your Position"
                    value={currentProfessionText}
                    onChange={(e) => setCurrentProfessionText(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form_single_item">
                  <select value={time} onChange={setTimeHandler} name="time">
                    <option value="">Choose Time...</option>
                    <option value="morning">
                      Morning starting 9:00 am to 5:00 pm
                    </option>
                    <option value="night">
                      evening starting 5:00 pm to 9:00 pm
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form_single_item">
                  <select
                    value={country}
                    onChange={setCountryHandler}
                    name="country"
                  >
                    <option value="">Choose Country...*</option>
                    {data.COUNTIRES.map((country, idx) => {
                      return (
                        <option key={idx} value={country.name}>
                          {country.name}
                        </option>
                      );
                    })}
                  </select>
                  {countryError && (
                    <span className="error-message">Country is required</span>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form_single_item">
                  <input
                    type={isDate ? "date" : "text"}
                    name="date_of_birth"
                    placeholder="Date of birth"
                    onFocus={() => setIsDate(true)}
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                  />
                </div>
              </div>
              <div className={`${type === "group" ? "col-lg-6" : "col-lg-12"}`}>
                <div className="form_single_item">
                  <select value={type} onChange={setTypeHandler} name="type">
                    <option value="">Individual/Group</option>
                    <option value="individual">Individual</option>
                    <option value="group">Group</option>
                  </select>
                </div>
              </div>

              {type === "group" && (
                <div className="col-lg-6">
                  <div className="form_single_item">
                    <input
                      value={number}
                      type="number"
                      name="number"
                      placeholder="Number"
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </div>
                </div>
              )}

              <div className="col-lg-12">
                <div className="form_single_item">
                  <textarea
                    name="message"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <input type="submit" className="btn_3" value="Submit" />
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;

import { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/blue-logo.png";
import Nav from "./Nav";
import * as $ from "jquery";

const Header = (props) => {
  useEffect(() => {
    $(window).on("scroll", function () {
      var window_top = $(window).scrollTop() + 1;
      if (window_top > 50) {
        $(".main_nav").addClass("menu_fixed animated fadeInDown");
      } else {
        $(".main_nav").removeClass("menu_fixed animated fadeInDown");
      }
    });
  }, []);

  return (
    <header className="header_part">
      <div className="main_nav">
        <div className="container-md container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="header_iner d-flex justify-content-between align-items-center">
                <div className="sidebar_icon troggle_icon d-lg-none">
                  <i className="icon_menu"></i>
                </div>
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="#" />
                  </Link>
                </div>

                <Nav />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

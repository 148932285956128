import { Link } from "react-router-dom";
import classes from "./Terms.module.css";

const Terms = () => {
  return (
    <>
      <div className={`${classes.terms} container py-5`}>
        <h1>Term & Conditions</h1>

        <section>
          <p>
            By accessing this page and using the Web Site
            <Link to="/"> https://verticalcareers.org/</Link> you acknowledge
            that you have read, understood and agree to be bound by and comply
            with the Terms and Conditions.
          </p>
          <p>
            If you do not agree with the Terms and Conditions please do not use
            this Web Site.
          </p>
        </section>

        <section>
          <h2>Copyright Notice</h2>
          <p>
            Unless otherwise noted, all information, text, articles, data,
            images, screens, web pages or other materials (collectively
            hereinafter referred to as the “Materials”) appearing on this Site
            are the exclusive property of Vcareers OÜ Limited or others as
            indicated. You may copy information from this Site for your personal
            or educational use only, provided that each copy includes any
            copyright or trademark notice or attribution as they appear on the
            pages copied. The Materials may not otherwise be copied, displayed,
            distributed, downloaded, licensed, modified, published, reposted,
            reproduced, reused, sold, transmitted, used to create a derivative
            work or otherwise used for public or commercial purposes, except as
            provided in these terms and conditions without the express written
            permission of Vcareers OÜ Limited. You may not use any content
            contained in the Materials in any manner that may give a false or
            misleading impression or statement, or to without permission gain
            access to third party systems. Nothing on this Site shall be
            construed as conferring any license of any intellectual property
            rights, whether by estoppel, implication or otherwise.
          </p>
        </section>

        <section>
          <h2>Trademark Notice</h2>
          <p>
            Vcareers OÜ, the Vcareers OÜ logo, are trademarks, service marks
            and/or registered trademarks of Vcareers OÜ Limited in Estonia and
            certain other jurisdictions. All other trademarks and service marks
            that appear in these pages are the property of their respective
            owners and are used solely to refer to those companies’ goods and
            services. Address any enquiries concerning usage of Vcareers OÜ
            Limited trademarks, copyrights, or designs to:{" "}
            <Link to="/contact-us">opsmanagers@verticalcareers.org</Link>.
          </p>
        </section>

        <section>
          <h2>Disclaimers of Warranties</h2>
          <p>
            Given the unpredictability of technology and the online environment,
            Vcareers OÜ Limited does not warrant that the function or operation
            of this Web site will be uninterrupted or error-free, that defects
            will be corrected, or that this Site or the server that makes it
            available will be free of viruses or other harmful elements. As a
            visitor to and user of this Site, you must assume full
            responsibility for any costs associated with servicing of equipment
            used in connection with use of our site. <br />
            Vcareers OÜ Limited does not represent or warrant that the
            information accessible via this Site is accurate, complete or
            current. This Site could contain typographical errors or technical
            inaccuracies. Vcareers OÜ Limited reserves the right to add to,
            change or delete its content or any part thereof without notice. Any
            price or availability information is subject to change without
            notice. Additionally, this Site may contain information provided by
            third parties. Vcareers OÜ Limited makes no representation or
            warranty regarding the accuracy, truth, quality, suitability or
            reliability of such information. Vcareers OÜ Limited is not
            responsible for any errors, omissions, or inaccuracies contained in
            any information provided by such third parties. Vcareers OÜ Limited
            does not endorse, warrant or guarantee any products or services
            offered on this Site. We are not a party to, and do not monitor, any
            transaction between users and third party providers of products or
            services.
          </p>
          <p>
            While efforts have been made to ensure the accuracy of the
            information on this Site, it should not be treated as the basis for
            formulating business decisions without professional advice. We
            emphasize that trademark and related intellectual property laws vary
            from country to country, and between jurisdictions within some
            countries. The information included on this Site will not be
            relevant or accurate for all countries or states.
          </p>
          <p>
            The contents of this site, including, without limitation, the
            materials, are provided “as is,” and Vcareers OÜ Limited makes no
            representations or warranties of any kind, express or implied, with
            respect to this site or its contents. Vcareers OÜ Limited disclaims
            all representations and warranties of any kind, including, without
            limitation, warranties of merchantability, fitness for a particular
            purpose, title and non-infringement.
          </p>
        </section>

        <section>
          <h2>Limitation of Liability</h2>
          <p>
            Neither Vcareers OÜ Limited nor any of its officers, members,
            directors, employees or other representatives will be liable for any
            damages, special, consequential or otherwise, arising out of or in
            connection with the use of this site or any information contained
            herein even if advised of the possibility thereof. This limitation
            of liability is comprehensive and applies to all damages of any
            kind, including, without limitation, loss of data, income or profit,
            loss of or damage to property and claims of third parties. You have
            sole responsibility for adequate protection and backup of data
            and/or equipment used in connection with the Site and will not make
            any claim against Vcareers OÜ Limited for lost data, re-run time,
            inaccurate output, work delays or lost profits resulting from the
            use of the materials.
          </p>
          <p>
            As a visitor to and a user of this Site, you, in effect, agree that
            all access and use of this Site and its contents are at your own
            risk.
          </p>
        </section>

        <section>
          <h2>Submissions</h2>
          <p>
            While we welcome your comments and feedback regarding this Site,
            Vcareers OÜ Limited is under no obligation to respond to all pieces
            of correspondence received through this Site, to maintain your
            submitted comments in confidence, or to pay compensation of any kind
            for your comments or submissions. We do not wish to receive any
            confidential or proprietary ideas, suggestions, materials, or
            information via this Site or any e-mail connection. All unsolicited
            comments, feedback, ideas, suggestions, and other submissions that
            are disclosed or submitted to Vcareers OÜ Limited through this Site
            shall become and remain the property of Vcareers OÜ Limited. Any
            such submission by you is a declaration of the full release of all
            proprietary claims and/or intellectual rights regarding your
            submission.
          </p>
        </section>

        <section>
          <h2>Links to Third Party Sites</h2>
          <p>
            Any other web sites that are accessible via links on this Site are
            not under the control of Vcareers OÜ Limited. Vcareers OÜ Limited is
            not responsible for the contents of any linked site or any link
            contained in a linked site, or any changes or updates to the
            information contained in such sites. Vcareers OÜ Limited provides
            the linked sites to you only as a convenience and the inclusion of
            any such link on the Site does not imply Vcareers OÜ Limited’s
            endorsement of the site, the organization operating such site, or
            any products or services of that organization. Your linking to any
            off-site pages or other sites is entirely at your own risk. Under no
            circumstances will Vcareers OÜ Limited be held responsible or
            liable, directly or indirectly, for any loss or damage that is
            caused or alleged to have been caused to you in connection with your
            use of, or reliance on, any content, goods or services available on
            any other site.
          </p>
        </section>

        <section>
          <h2>References to Products or Services</h2>
          <p>
            Information on this Site may contain references or cross references
            to Vcareers OÜ Limited products or services that are not announced
            or available in your country. Such references do not imply that
            Vcareers OÜ Limited intends to announce such products or services in
            your country. Please contact Vcareers OÜ Limited for information
            regarding the products and services, which may be available to you.
          </p>
        </section>

        <section>
          <h2>Governing Law</h2>
          <p>
            These terms and conditions will be governed by and construed in
            accordance with the laws of the Estonian , without regard to any
            principles of conflicts of law. You agree that any action at law or
            in equity that arises out of or relates to these terms and
            conditions will be filed only in the High or county courts located
            in Tallinn, Estonia.
          </p>
        </section>

        <section>
          <h2>Complete Agreement</h2>
          <p>
            These terms and conditions represent the entire understanding
            relating to the use of the Site and prevail over any prior or
            contemporaneous, conflicting or additional, communications. Vcareers
            OÜ Limited reserves the right to revise these terms and conditions
            at any time without notice by updating this posting. Enquiries
          </p>
          <p>
            Thank you for visiting{" "}
            <Link to="/">https://verticalcareers.org/</Link>. Should you have
            any questions concerning any of our policies, we encourage you to
            contact the{" "}
            <Link to="/contact-us">opsmanagers@verticalcareers.org</Link> .
          </p>
        </section>

        <span>Updated: April 2023</span>
      </div>
    </>
  );
};

export default Terms;

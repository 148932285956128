import Banner from "../components/Home Components/Banner";
import About from "../components/Home Components/About";
import Courses from "../components/Home Components/Courses";

const Home = () => {
  return (
    <>
      <Banner />

      <About />

      <Courses />
    </>
  );
};

export default Home;

import { Link } from "react-router-dom";
import data from "../../config/data.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Courses = () => {
  return (
    <section id="courses" className="popular_course section_padding section_bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6" data-aos="fade-up" data-aos-duration="1200">
            <div className="section_tittle">
              <h2>
                Explore our <br />
                Popular Courses
              </h2>
              <p>
                Jeffrey crikey victoria sponge mush spiffing super arse over tit
                matie boy smashing. The little rotter off his nut codswallop.!
              </p>
            </div>
          </div>
          <div className="col-lg-12">
            <OwlCarousel
              items="3"
              loop="true"
              nav="false"
              dots="true"
              autoplay="true"
              autoplayHoverPause="true"
              smartSpeed="300"
              dotsSpeed="300"
              responsiveClass="true"
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                991: {
                  items: 2,
                },
                1200: {
                  items: 3,
                },
              }}
              id="courses"
              className="popular_courses_item owl-carousel"
            >
              {data.POPULAR_COURSES.map((course) => {
                return (
                  <div
                    key={course.id}
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    className="single_courses_item"
                  >
                    <Link to={`/courses/${course.id}`}>
                      <img
                        src={require(`../../assets/imgs/courses/${course.img}`)}
                        alt="#"
                        className="img-fluid"
                      />
                    </Link>
                    <div className="courses_item_iner">
                      {/* <div className="courses_review">
                        <a className="courses_btn">$16.00</a>
                        <div className="review_icon">
                          <i className="icon_star"></i>
                          <i className="icon_star"></i>
                          <i className="icon_star"></i>
                          <i className="icon_star"></i>
                          <i className="icon_star"></i>
                        </div>
                        <span>(120 Reviews)</span>
                      </div> */}
                      <Link to={`/courses/${course.id}`}>
                        <h4>{course.title}</h4>
                      </Link>
                      {/* <a href="teacher.html" className="course_author">
                        <img
                          src={require("../../assets/imgs/author_1.jpeg")}
                          alt="#"
                        />
                      </a>
                      <p className="author_name">
                        <a href="teacher.html">{course.instructor.name}</a>
                      </p> */}
                      {/* <div className="popular_course_list">
                        <p>
                          <i className="icon_book_alt"></i>
                          {course.noOfLessons} Lessons
                        </p>
                        <p>
                          <i className="icon_profile"></i>
                          {course.students}
                        </p>
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Courses;
